// noinspection JSUnusedGlobalSymbols

import * as ko from "knockout";
import {Context} from "@profiscience/knockout-contrib-router";
import {autobind, observable, unwrap} from "knockout-decorators";
import * as L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/images/marker-icon-2x.png";
import "leaflet/dist/images/marker-shadow.png";
import {AddressDto, InstitutionDto, InstitutionDtoTypeEnum} from "../../../api/generated";
import {dataApi} from "../../../api/api-wrapper";
import {institutionTypeOptions} from "../../../components/util/common";
import i18nextko from "../../../bindings/i18nko";
import {postbox} from "../../../components/util/postbox";

class ViewModelContext extends Context {
    institution: InstitutionDto;
}

class ViewModel {

    @observable({deep: true, expose: true})
    selectedInstitution: InstitutionDto;

    map: L.Map;

    constructor(ctx: ViewModelContext) {
        this.selectedInstitution = this.createInstitution();

        this.map = L.map('map').setView({ "lat": 47.67528751902042, "lng": 13.19405034184456 }, 7);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);

        dataApi.getInstitutions("full").then(value => {
            console.log("getInstitutions", value);
        });

        unwrap(this.selectedInstitution, "name").extend({required: true, maxlength: 255});
        unwrap(this.selectedInstitution.address, "country").extend({required: true, maxlength: 255});
        unwrap(this.selectedInstitution.address, "city").extend({required: true, maxlength: 65532555});
        unwrap(this.selectedInstitution.address, "zip").extend({required: true, maxlength: 255});
        unwrap(this.selectedInstitution.address, "street").extend({required: true, maxlength: 255});
        unwrap(this.selectedInstitution.address, "housenumber").extend({required: true, maxlength: 255});
        unwrap(this.selectedInstitution.address, "staircase").extend({required: false, maxlength: 255});

    }

    @autobind
    public save() {

        const errors = ko.validation.group(this.selectedInstitution)
        if (errors().length > 0) {
            errors.showAllMessages();
            postbox.addError('validation.failed');
            return;
        }


        console.debug(this.selectedInstitution);

        const nominatimUrl = new URL("https://nominatim.openstreetmap.org/search");
        nominatimUrl.searchParams.append("format", "json");
        nominatimUrl.searchParams.append("country", this.selectedInstitution.address.country);
        nominatimUrl.searchParams.append("city", this.selectedInstitution.address.city);
        nominatimUrl.searchParams.append("postalcode", this.selectedInstitution.address.zip.toString());
        nominatimUrl.searchParams.append("street", this.selectedInstitution.address.housenumber
            + (this.selectedInstitution.address.staircase ? ' ' + this.selectedInstitution.address.staircase : '')
            + ' ' + this.selectedInstitution.address.street);

        console.debug("nominatimUrl", nominatimUrl.href);

        fetch(nominatimUrl.href)
            .then(response => {
                //console.debug("response", response);
                return response.json();
            })
            .then(locations => {
                console.debug("locations", locations)

                if(locations && locations.length > 0) {


                    const location = locations[0];
                    const latLng  = new L.LatLng(location.lat, location.lon);

                    L.marker(latLng).addTo(this.map)
                        .bindPopup(location.display_name)
                        .openPopup();
                    this.map.panTo(latLng);

                    this.selectedInstitution.address.latitude = location.lat;
                    this.selectedInstitution.address.longitude = location.lon;
                    dataApi.postInstitution(this.selectedInstitution).then(value => console.debug(value));
                }


            });

        return null;
    }

    /**
     * Get the options for the institution type.
     */
    public institutionTypeOptions() {
        return institutionTypeOptions();
    }

    @autobind
    info() {
        console.debug(this.map.getBounds(), this.map.getZoom(), this.map.getCenter());
    }

    createAddress(): AddressDto {
        return {
            country: "Österreich",
            city: "",
            zip: null,
            street: "",
            housenumber: "",
            staircase: null,
            longitude: null,
            latitude: null
        }
    }

    createInstitution(): InstitutionDto {
        return {
            name: "",
            type: InstitutionDtoTypeEnum.Therapy,
            address: this.createAddress(),
            departments: [],
            barrierFree: false,
        }
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./location.html'),
    componentName: "location",
    loader: (ctx: ViewModelContext) => {
        const id = ctx.params.id;
        if (id == null) {
            ctx.institution = null;
            return Promise.resolve();
        }
        return dataApi.getInstitution(id).then(response => ctx.institution = response.data);
    }
};
