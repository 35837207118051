// noinspection JSUnusedGlobalSymbols

import {Context} from "@profiscience/knockout-contrib-router";
import {observable} from "knockout-decorators";
import "leaflet/dist/leaflet.css";
import "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/images/marker-icon-2x.png";
import "leaflet/dist/images/marker-shadow.png";
import {PersonDto} from "../../../api/generated";
import {dataApi} from "../../../api/api-wrapper";
import {dataTableOptions} from "../../../components/util/common";
import {postbox} from "../../../components/util/postbox";
import "../../../bindings/knockout-datatables-foreach"


class ViewModelContext extends Context {
    persons: PersonDto[];
}

class ViewModel {

    @observable({deep: false, expose: true})
    persons: PersonDto[];

    constructor(ctx: ViewModelContext) {
        this.persons = ctx.persons || [];
        console.debug("Persons", ctx.persons);
    }

    public dataTableOptions() {
        return dataTableOptions();
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./persons.html'),
    componentName: "persons",
    loader: (ctx: ViewModelContext) => {
        return dataApi.getPersons("full")
            .then(response => ctx.persons = response.data)
            .catch(reason => {
                console.debug(reason);
                postbox.addError('');
            });
    }
};
