import * as ko from 'knockout';
import {components} from 'knockout';
import Config = components.Config;

interface ViewModelParams {

}

class ViewModel {
    constructor(private params: ViewModelParams) {

    }
}

const component: Config = {
    viewModel: (params: ViewModelParams) => {},
    template: <string>require('./service-docview.html')
};

export default component;

if (!ko.components.isRegistered('service-docview')) {
    ko.components.register('service-docview', component)
}
