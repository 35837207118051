import * as ko from "knockout";
import {Observable, ObservableArray} from "knockout";
import * as common from "../_common"
import {FilesData} from "../_common"
import {observableArray} from "knockout-decorators"
import i18nextko from "../../../bindings/i18nko";
import globalState from "../../../global-state";
import {contentApi} from "../../../api/api-wrapper";
import {UploadDto} from "../../../api/generated";


/**
 * Display parameters for widget.
 */
interface WidgetGalleryViewModelParams extends common.WidgetComponentCompositionContext {

}

/**
 * A gallery - only a stub at the moment.
 */
class WidgetGalleryViewModel extends common.WidgetComponentCompositionModel<common.WidgetContentGallery> {

    public basePath: string = config.attachmentEndpoint;

    constructor(ctx: WidgetGalleryViewModelParams) {
        super(ctx);
        if (typeof this.widgetContent.items === 'undefined') {
            observableArray({deep: true, expose: true})(this.widgetContent, "items");
            (<common.WidgetContentGallery>ctx.widget.content).items = [];
        }
    }

    public editComponent() {
        return "widget-gallery-edit";
    }

}

ko.components.register("widget-gallery", {
    viewModel: (params: WidgetGalleryViewModelParams) => new WidgetGalleryViewModel(params),
    template: <string>require('./widget-gallery.html')
});

/**
 * Editor parameters for widget.
 */
interface WidgetGalleryEditViewModelParams extends common.WidgetComponentEditContext {
}

/**
 * Editor view model. Currently a stub only.
 */
class WidgetGalleryEditViewModel extends common.WidgetComponentEditModel<common.WidgetContentGallery> {

    public imagesData: Observable<FilesData>;

    constructor(ctx: WidgetGalleryEditViewModelParams) {
        super(ctx);

        this.imagesData = ko.observable({
            file: ko.observable(),
            dataURL: ko.observable(),
            fileArray: <ObservableArray<File>>ko.observableArray(),
            base64StringArray: <ObservableArray<string>>ko.observableArray()
        });

        ctx.callback.getResolveData = () => {
            if (this.imagesData() && this.imagesData().fileArray().length > 0) {
                globalState.loading(true);

                // close modal immediately
                window.setTimeout(() => {
                    Promise.all(
                        this.imagesData().fileArray()
                            .filter(file =>
                                file.type.search(/^image\/.*$/) != -1)
                            .map(file =>
                                this.upload(file)))
                        .then(uploads =>
                            contentApi
                                .postUploads(uploads)
                                .then(response => {
                                    const paths = response.data;
                                    this.widgetContent.items = [];
                                    paths.forEach((path, i) => {
                                        this.widgetContent.items.push({
                                            ref: path,
                                            mimetype: this.imagesData().fileArray()[i].type,
                                            collapsible: false,
                                            title: null
                                        });
                                    });
                                }))
                        .catch(err =>
                            console.error(err))
                        .finally(() =>
                            globalState.loading(false));
                });
            }

            return Promise.resolve();
        };
    }

    public imageFileOptions() {
        return {
            noFileText: i18nextko.t("widget.modal.file.noFileText"),
            buttonText: i18nextko.t("widget.modal.file.button.choose"),
            changeButtonText: i18nextko.t("widget.modal.file.button.change"),
            clearButtonText: i18nextko.t("widget.modal.file.button.clear")
        };
    }

    public upload(file: File): Promise<UploadDto> {

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let encoded = (<string>reader.result).replace(/^data:(.*;base64,)?/, '');
                if ((encoded.length % 4) > 0) {
                    encoded += '='.repeat(4 - (encoded.length % 4));
                }
                resolve(encoded);
            };
            reader.onerror = error => reject(error);
        }).then(base64data => {
            return <UploadDto>{
                filename: file.name,
                mimeType: file.type,
                data: base64data
            }
        });
    }
}

ko.components.register("widget-gallery-edit", {
    viewModel: (params: WidgetGalleryEditViewModelParams) => new WidgetGalleryEditViewModel(params),
    template: <string>require('./widget-gallery-edit.html')
});
