// import styles
import '../scss/main.scss';

// application imports
import "core-js/stable";
import "regenerator-runtime/runtime";
import $ from 'jquery';
import {App} from './app.ts';

// load ie polyfills
import 'url-polyfill';

// initialize the app
$(function () {
    'use strict';
    console.debug('main: initializing XEOMOTION ...');

    try {
        new App().init().then(() =>
            console.debug('main: XEOMOTION successfully initialized'));

    } catch (err) {
        console.error("main: XEOMOTION failed to initialize", err);
    }
});
