// noinspection JSUnusedGlobalSymbols

import {Context} from "@profiscience/knockout-contrib-router";

class ViewModelContext extends Context {
}

class ViewModel {

    constructor(ctx: ViewModelContext) {
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./links.html'),
    componentName: "links",
    loader: (ctx: ViewModelContext) => {
        return Promise.resolve();
    }
};
