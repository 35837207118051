// noinspection JSUnusedGlobalSymbols

import {Context} from "@profiscience/knockout-contrib-router";
import {autobind, observable, unwrap} from "knockout-decorators";
import "leaflet/dist/leaflet.css";
import "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/images/marker-icon-2x.png";
import "leaflet/dist/images/marker-shadow.png";
import {DepartmentDto, PersonDto} from "../../../api/generated";
import {dataApi} from "../../../api/api-wrapper";
import * as ko from "knockout";
import {Observable, ObservableArray} from "knockout";
import {postbox} from "../../../components/util/postbox";
import "./department-edit"


class ViewModelContext extends Context {
    department: DepartmentDto;
    params: any;
}

class ViewModel {

    @observable({deep: true, expose: true})
    department: DepartmentDto;

    languageOptions: ObservableArray<string>;

    languageQuery: Observable<string>;

    constructor(ctx: ViewModelContext) {
        this.department = ctx.department || this.createDepartment();

        unwrap(this.department, "name").extend({required: true, maxlength: 255});
        unwrap(this.department, "phone").extend({required: false, maxlength: 255 });
        unwrap(this.department, "fax").extend({required: false, maxlength: 255 });
        unwrap(this.department, "email").extend({required: false, email: true, maxlength: 255 });
        unwrap(this.department, "website").extend({required: false, maxlength: 255 });

    }

    @autobind
    public save() {

        const errors = ko.validation.group(this.department)
        if (errors().length > 0) {
            errors.showAllMessages();
            postbox.addError('validation.failed');
            return;
        }

        if (this.department.id && this.department.id.trim().length > 0) {
            dataApi.putDepartment(this.department.id, this.department)
                .then(response => this.department = response.data)
                .catch(error => {
                    postbox.addError('department.error.save');
                    return Promise.reject();
                });
        } else {
            dataApi.postDepartment(this.department)
                .then(response => this.department = response.data)
                .catch(error => {
                    postbox.addError('department.error.save');
                    return Promise.reject();
                });
        }
    }

    public info() {
        console.debug(this.department, this.languageQuery());
    }


    createDepartment(): DepartmentDto {
        return {
            'name': '',
            'phone': '',
            'fax': '',
            'email': '',
            'website': ''
        }
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./department.html'),
    componentName: "department",
    loader: (ctx: ViewModelContext) => {
        const id = ctx.params.id;
        if (id == null) {
            ctx.department = null;
            return Promise.resolve();
        }
        return dataApi.getDepartment(id).then(response => ctx.department = response.data);
    }
};
