import * as ko from 'knockout';
import {components} from 'knockout';
import Config = components.Config;

interface ViewModelParams {

}

class ViewModel {
    constructor(private params: ViewModelParams) {

    }
}

const component: Config = {
    viewModel: (params: ViewModelParams) => {},
    template: <string>require('./home-public.html')
};

export default component;

if (!ko.components.isRegistered('home-public')) {
    ko.components.register('home-public', component)
}
