// noinspection JSUnusedGlobalSymbols

import {Context} from "@profiscience/knockout-contrib-router";

class ViewModelContext extends Context {
}

class ViewModel {

    public doccheckFormId: string;

    constructor(ctx: ViewModelContext) {
        this.doccheckFormId = config.doccheckFormId;
    }

    public get formUrl() {
        return 'https://login.doccheck.com/code/at/' + this.doccheckFormId + '/login_m/';
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./login.html'),
    componentName: "login",
    loader: (ctx: ViewModelContext) => {
        return Promise.resolve();
    }
};
