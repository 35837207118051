import * as ko from 'knockout';
import {components} from 'knockout';
import Config = components.Config;

interface ViewModelParams {

}

class ViewModel {
    constructor(private params: ViewModelParams) {

    }
}

const component: Config = {
    viewModel: (params: ViewModelParams) => {},
    template: <string>require('./faqs-public.html')
};

export default component;

if (!ko.components.isRegistered('faqs-public')) {
    ko.components.register('faqs-public', component)
}
