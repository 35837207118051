// noinspection JSUnusedGlobalSymbols

import * as ko from "knockout";
import {components} from "knockout";
import {autobind, observable, unwrap} from "knockout-decorators";
import * as L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/images/marker-icon-2x.png";
import "leaflet/dist/images/marker-shadow.png";
import {InstitutionDto} from "../../../api/generated";
import {dataApi} from "../../../api/api-wrapper";
import {createInstitution, institutionTypeOptions} from "../../../components/util/common";
import {postbox} from "../../../components/util/postbox";
import globalState from "../../../global-state";
import Config = components.Config;
import {createConfirmModal} from "../../../components/elements/modal/modal";
import i18nextko from "../../../bindings/i18nko";
import {Router} from "@profiscience/knockout-contrib-router";

interface ViewModelParams {
    institution: InstitutionDto,
    successCallback: (vm: InstitutionEditViewModel) => void;
    cancelCallback: (vm: InstitutionEditViewModel) => void;
}

export class InstitutionEditViewModel {

    @observable({deep: true, expose: true})
    institution: InstitutionDto;

    public successCallback: (vm: InstitutionEditViewModel) => void;
    public cancelCallback: (vm: InstitutionEditViewModel) => void;

    map: L.Map;

    constructor(params: ViewModelParams) {
        console.log(params.institution);
        this.institution = params.institution || this.createInstitution();

        this.successCallback = params.successCallback || function() {};
        this.cancelCallback = params.cancelCallback || function() {};

        this.map = L.map('map').setView({ "lat": 47.67528751902042, "lng": 13.19405034184456 }, 7);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);

        unwrap(this.institution, "name").extend({required: true, maxlength: 255});
        unwrap(this.institution, "type").extend({required: true, maxlength: 255});
        unwrap(this.institution.address, "country").extend({required: true, maxlength: 255});
        unwrap(this.institution.address, "city").extend({required: true, maxlength: 65532555});
        unwrap(this.institution.address, "zip").extend({required: true, maxlength: 255});
        unwrap(this.institution.address, "street").extend({required: true, maxlength: 255});
        //unwrap(this.institution.address, "housenumber").extend({required: true, maxlength: 255});
        //unwrap(this.institution.address, "staircase").extend({required: false, maxlength: 255});
    }

    @autobind
    public save() {

        const errors = ko.validation.group(this.institution)
        if (errors().length > 0) {
            errors.showAllMessages();
            postbox.addError('validation.failed');
            return;
        }

        globalState.loading(true);

        const country = this.institution.address.country;
        const city = this.institution.address.city;
        const zip = this.institution.address.zip;
        const street = this.institution.address.street;
        const housenumber = this.institution.address.housenumber;
        const staircase = this.institution.address.staircase;

        // If home visits only - search for city and zip only
        if(this.institution.homeVisitsOnly || this.institution.hideAddress) {
            return this.nominatimSearch(country, city, zip, null, null, null)
                .then(locations => {
                    console.debug("Home visits address search", locations, country, city, zip);
                    if(locations && locations.length > 0) {
                        return this.saveInstitution(locations);
                    } else {
                        postbox.addError('institution.error.addressNotFound');
                        return Promise.reject();
                    }
                })
                .finally(globalState.loading(false));
        } else {
            // search for full address, with fallbacks if housenumber and staircase are unknown.
            return this.nominatimSearch(country, city, zip, street, housenumber, staircase)
                .then(locations => {
                    console.debug("Full address search", locations);
                    if(!locations || locations.length < 1) {
                        console.debug("Searching without staircase");
                        return this.nominatimSearch(country, city, zip, street, housenumber, null);
                    }
                    return locations;
                })
                .then(locations => {
                    if(!locations || locations.length < 1) {
                        console.debug("Searching without housenumber and staircase");
                        return this.nominatimSearch(country, city, zip, street, null, null);
                    }
                    return locations;
                })
                .then(locations => {

                    if(locations && locations.length > 0) {
                        return this.saveInstitution(locations);
                    } else {
                        postbox.addError('institution.error.addressNotFound');
                        return Promise.reject();
                    }
                })
                .finally(globalState.loading(false));
        }
    }

    private saveInstitution(locations): Promise<InstitutionDto> {
        console.debug("Saving institution");
        const location = locations[0];
        const latLng = new L.LatLng(location.lat, location.lon);

        this.institution.address.latitude = location.lat;
        this.institution.address.longitude = location.lon;
        if (this.institution.id) {
            return dataApi.putInstitution(this.institution.id, this.institution)
                .then(response => {
                    this.institution = response.data;
                    postbox.addSuccess('institution.success.save');
                    this.successCallback(this);
                    return response.data;
                })
                .catch(error => {
                    postbox.addError('institution.error.save');
                    return Promise.reject();
                });
        } else {
            return dataApi.postInstitution(this.institution)
                .then(response => {
                    this.institution = response.data;
                    postbox.addSuccess('institution.success.save');
                    this.successCallback(this);
                    return response.data;
                })
                .catch(error => {
                    postbox.addError('institution.error.save');
                    return Promise.reject();
                });
        }
    }

    public cancel() {
        this.cancelCallback(this);
    }

    /**
     * Search for a location.
     * @param country
     * @param city
     * @param postalcode
     * @param street
     * @param housenumber
     * @param staircase
     * @private
     */
    private nominatimSearch(country: string, city: string, postalcode: string, street: string, housenumber: string,
                            staircase: string): Promise<any> {

        const nominatimUrl = new URL("https://nominatim.openstreetmap.org/search");
        nominatimUrl.searchParams.append("format", "json");
        nominatimUrl.searchParams.append("country", country);
        if(city) {
            nominatimUrl.searchParams.append("city", city);
        }
        nominatimUrl.searchParams.append("postalcode", postalcode);

        const streetTerm = this.streetTerm(street, housenumber, staircase);
        if(streetTerm) {
            nominatimUrl.searchParams.append("street", streetTerm);
        }

        console.debug("nominatimUrl", nominatimUrl.href);

        return fetch(nominatimUrl.href)
            .then(response => {
                console.debug("response", response);
                return response.json();
            });
    }

    /**
     * Creates a search term for street in following structure:
     * <housenumber> <staircase> <street>
     * @param street
     * @param housenumber
     * @param staircase
     * @private
     */
    private streetTerm(street: string, housenumber: string, staircase: string): string {
        const streetName = street && street.trim().length > 0  ? street.trim() : null;
        const houseNr = housenumber  && housenumber.trim().length > 0 ? housenumber.trim() : null;
        const stairNr = staircase && staircase.trim().length > 0 ? staircase.trim() : null;

        let term = '';

        if(streetName) {
            term = streetName;

            if(houseNr) {
                if(stairNr) {
                    term =  stairNr + ' ' + term;
                }
                term = houseNr  + ' ' + term;
            }
        }

        return term.trim().length > 0 ? term : null;
    }



    /**
     * Delete the institution with a confirm dialog.
     */
    @autobind
    public deleteInstitution() {
        return createConfirmModal(
            i18nextko.t("institution.delete.confirmText"), i18nextko.t("institution.delete.confirmTitle"),
            i18nextko.t("global.delete"),
            i18nextko.t("global.cancel")
        )
            .then(() => {
                globalState.loading(true);
                return dataApi.deleteInstitution(this.institution.id).then(() => {
                    postbox.addSuccess('institution.delete.success');
                    Router.update("/admin/institutionen")
                })
                    .catch(err => {
                        postbox.addError('institution.delete.error');
                        return Promise.reject(err);
                    })
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => globalState.loading(false));
    }

    /**
     * Get the options for the institution type.
     */
    public institutionTypeOptions() {
        return institutionTypeOptions();
    }

    @autobind
    info() {
        console.debug(this.map.getBounds(), this.map.getZoom(), this.map.getCenter());
    }

    createInstitution(): InstitutionDto {
        return createInstitution();
    }
}

const component: Config = {
    viewModel: (params: ViewModelParams) => new InstitutionEditViewModel(params),
    template: <string>require('./institution-edit.html')
};

export default component;

if (!ko.components.isRegistered('institution-edit')) {
    ko.components.register('institution-edit', component)
}
