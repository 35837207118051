// noinspection JSUnusedGlobalSymbols

import * as ko from 'knockout'
import {ObservableArray} from 'knockout'
import {Context, Router} from "@profiscience/knockout-contrib-router";
import {autobind, computed, observable} from "knockout-decorators";
import "leaflet/dist/leaflet.css";
import "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/images/marker-icon-2x.png";
import "leaflet/dist/images/marker-shadow.png";
import {DepartmentDto, InstitutionDto, PersonDto, SearchResultItemDto} from "../../../api/generated";
import {dataApi} from "../../../api/api-wrapper";
import "./person-edit"
import {createPerson, createSearchResultItem, displayName, isAdminView} from "../../../components/util/common";
import {PersonEditViewModel} from "./person-edit";
import "../../../components/elements/person-department/person-department-edit"
import {PersonDepartmentEditViewModel} from "../../../components/elements/person-department/person-department-edit";
import {SearchResultItem} from "../../../components/elements/search-result/search-result-item";
import {createConfirmModal} from "../../../components/elements/modal/modal";
import i18nextko from "../../../bindings/i18nko";
import globalState from '../../../global-state';
import {postbox} from "../../../components/util/postbox";


class ViewModelContext extends Context {
    person: PersonDto;
    institutions: InstitutionDto[];
    departments: DepartmentDto[];
    searchResultItems: SearchResultItemDto[];
}

class ViewModel {

    @observable({deep: true, expose: true})
    person: PersonDto;

    institutions: ObservableArray<InstitutionDto>;
    departments: ObservableArray<DepartmentDto>;

    /**
     * All existing search result items.
     */
    @observable({deep: false, expose: false})
    searchResultItems: SearchResultItemDto[];

    /**
     * The search result item to edit.
     */
    @observable({deep: true, expose: true})
    searchResultItem: SearchResultItemDto;

    @observable
    searchResultItemEditing: boolean;

    @observable
    selectedInstitutionId: string;

    constructor(ctx: ViewModelContext) {
        this.person = ctx.person || this.createPerson();
        if(!this.isAdminView && !ctx.person) {
            Router.update("/")
        }
        this.institutions = ko.observableArray(ctx.institutions || []);
        this.departments =  ko.observableArray(ctx.departments || []);
        this.searchResultItems = ctx.searchResultItems ? ctx.searchResultItems.map(item => {
            // ensure every item has a medicate property to make it an observable
            return Object.assign({}, { medicate: undefined }, item);
        }) : [];
        this.searchResultItem = this.createSearchResultItem();
        this.searchResultItemEditing = false;
        this.selectedInstitutionId = "";
    }

    public get isAdminView() {
        return isAdminView();
    }

    createPerson(): PersonDto {
        return createPerson();
    }

    public get email(): string {
        return this.person.email;
    }

    public get subject() {
        return i18nextko.t('email.invitation.subject')();
    }

    public get body() {
        const salutation = i18nextko.t(`email.salutation.${this.person.gender.toLowerCase()}`,
            { displayName: displayName(this.person) })()
        const body =  i18nextko.t('email.invitation.body')();
        const link = `${config.auth0Options.authorizationParams.redirect_uri}/selfservice/${this.person.id}`;
        const signature = i18nextko.t('email.signature')();

        return `${salutation}${body}${link}${signature}`;
    }
    @computed
    public get mailHref(): string {
        return `mailto:${this.email}?subject=${encodeURI(this.subject)}&body=${encodeURI(this.body)}`;
    }

    @autobind
    public personSuccessCallback(vm: PersonEditViewModel) {
        if(this.isAdminView) {
            return Router.update(`/admin/personen/${vm.person.id}` );
        } else {
            return Router.update(`/selfservice/${vm.person.id}` );
        }
    }

    @autobind
    public personCancelCallback(vm: PersonEditViewModel) {
        Router.update("/admin/personen")
    }

    @autobind
    public personDepartmentSuccessCallback(vm: PersonDepartmentEditViewModel) {
        const item = vm.item;
        const index = this.searchResultItems.findIndex(i => i.id == item.id );
        if(index === -1) {
            this.searchResultItems.push(item);
        } else {
            this.searchResultItems.splice(index, 1, item);
        }
        this.searchResultItemEditing = false;
    }

    @autobind
    public personDepartmentCancelCallback(vm: PersonDepartmentEditViewModel) {
        this.searchResultItemEditing = false;
    }

    @autobind
    private createSearchResultItem() {
        return createSearchResultItem(this.person);
    }

    @autobind
    public addSearchResultItem() {
        this.searchResultItem = this.createSearchResultItem();
        this.searchResultItemEditing = true;
    }

    @autobind
    public editSearchResultItem(item: SearchResultItem) {
        this.searchResultItem = item.dto;
        this.searchResultItemEditing = true;
    }

    @computed
    get searchResultItemsComputed(): SearchResultItem[] {
        return this.searchResultItems.map(dto => new SearchResultItem(dto));
    }

    /**
     * Delete the search result item with a confirm dialog.
     */
    @autobind
    public deleteSearchResultItem(item: SearchResultItem) {
        return createConfirmModal(
            i18nextko.t("personDepartment.delete.confirmText"), i18nextko.t("personDepartment.delete.confirmTitle"),
            i18nextko.t("global.delete"),
            i18nextko.t("global.cancel")
        )
            .then(() => {
                globalState.loading(true);
                return dataApi.deletePersonDepartment(item.dto.id).then(() => {
                    postbox.addSuccess('personDepartment.delete.success');
                    const index = this.searchResultItems.findIndex(searchResultItem => searchResultItem.id == item.dto.id);
                    if(index !== -1) {
                        this.searchResultItems.splice(index, 1);
                    }
                })
                .catch(err => {
                    postbox.addError('personDepartment.delete.error');
                    return Promise.reject(err);
                })
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => globalState.loading(false));
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./person.html'),
    componentName: "person",
    loader: (ctx: ViewModelContext) => {
        const id = ctx.params.id;

        const personPromise = (id == null) ? Promise.resolve(null) :
            dataApi.getPerson(id)
                .then(response => ctx.person = response.data)
                .catch(reason => {
                    console.log(reason)
                });

        const personDepartmentPromise = (id == null) ? Promise.resolve(null) :
                dataApi.getPersonDepartmentsByPersonId(id)
                    .then(response => ctx.searchResultItems = response.data)
                    .catch(reason => {
                        console.log(reason)
                    });

        return Promise.all([
            personPromise,
            personDepartmentPromise,
            dataApi.getInstitutions().then(response => ctx.institutions = response.data),
            dataApi.getDepartments().then(response => ctx.departments = response.data),
        ]);
    }
};
