class NotFoundPageViewModel {

}

// noinspection JSUnusedLocalSymbols
function load(ctx) {
    return Promise.resolve();
}

export default <KnockoutLazyPageDefinition>{
    viewModel: NotFoundPageViewModel,
    loader: load,
    template: require('./404.html'),
    componentName: '404-page'
};
