// noinspection JSUnusedGlobalSymbols
import * as ko from "knockout";
import {Context} from "@profiscience/knockout-contrib-router";
import {observable} from "knockout-decorators";
import {InstitutionDto} from "../../../api/generated";
import {dataApi} from "../../../api/api-wrapper";
import {dataTableOptions} from "../../../components/util/common";
import "../../../bindings/knockout-datatables-foreach"
import {ObservableArray} from "knockout";

class ViewModelContext extends Context {
    institutions: InstitutionDto[];
}

class ViewModel {

    institutions: ObservableArray<InstitutionDto>;

    constructor(ctx: ViewModelContext) {
        console.debug("institutions", ctx.institutions)
        this.institutions = ko.observableArray(ctx.institutions || []);
    }

    public dataTableOptions() {
        return dataTableOptions();
    }

}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./institutions.html'),
    componentName: "institutions",
    loader: (ctx: ViewModelContext) => {
        return dataApi.getInstitutions().then(response => ctx.institutions = response.data);
    }
};
