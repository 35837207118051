import * as ko from "knockout";
import {components, Observable} from "knockout";
import * as common from "../_common";
import {FileData} from "../_common";
import {observable} from "knockout-decorators";
import 'knockout-file-bindings/knockout-file-bindings.css';
import {contentApi} from "../../../api/api-wrapper";
import i18nextko from "../../../bindings/i18nko";
import globalState from "../../../global-state";
import {postbox} from "../../../components/util/postbox";
import Config = components.Config;

/**
 * Display parameters for widget.
 */
interface WidgetImageViewModelParams extends common.WidgetComponentCompositionContext {

}

/**
 * Shows an image.
 */
class WidgetImageViewModel extends common.WidgetComponentCompositionModel<common.WidgetContentImage> {

    public basePath: string = config.attachmentEndpoint;

    constructor(ctx: WidgetImageViewModelParams) {
        super(ctx);
        if (typeof this.widgetContent.ref === 'undefined') {
            observable({deep: true, expose: true})(this.widgetContent, "ref");
            (<common.WidgetContentImage>ctx.widget.content).ref = null;
        }
    }

    public editComponent() {
        return "widget-image-edit";
    }
}

ko.components.register("widget-image", <Config>{
    viewModel: (params: WidgetImageViewModelParams) => new WidgetImageViewModel(params),
    template: <string>require('./widget-image.html')
});

/**
 * Editor parameters for widget.
 */
interface WidgetImageEditViewModelParams extends common.WidgetComponentEditContext {
}

/**
 * Editor view model. Currently a stub only.
 */
class WidgetImageEditViewModel extends common.WidgetComponentEditModel<common.WidgetContentImage> {

    public imageData: Observable<FileData>;

    constructor(ctx: WidgetImageEditViewModelParams) {
        super(ctx);

        this.imageData = ko.observable({
            dataURL: ko.observable(),
            base64String: ko.observable(),
            file: ko.observable()
        });

        ctx.callback.getResolveData = () => {
            if (this.imageData() && this.imageData().base64String()) {
                if (this.imageData().file().type.search(/^image\/.*$/) == -1) {
                    postbox.addError('widget.image.error.invalidType');

                    return Promise.reject();
                }

                globalState.loading(true);

                // close modal immediately
                window.setTimeout(() => {
                    contentApi
                        .postUploads([
                            {
                                filename: this.imageData().file().name,
                                mimeType: this.imageData().file().type,
                                data: this.imageData().base64String()
                            }
                        ])
                        .then(response => {
                            this.widgetContent.ref = response.data[0];
                            this.widgetContent.mimetype = this.imageData().file().type;
                        })
                        .catch(err => console.error(err))
                        .finally(() => globalState.loading(false));
                });
            }

            return Promise.resolve();
        }
    }

    public imageFileOptions() {
        return {
            noFileText: i18nextko.t("widget.modal.file.noFileText"),
            buttonText: i18nextko.t("widget.modal.file.button.choose"),
            changeButtonText: i18nextko.t("widget.modal.file.button.change"),
            clearButtonText: i18nextko.t("widget.modal.file.button.clear")
        };
    }
}

ko.components.register("widget-image-edit", <Config>{
    viewModel: (params: WidgetImageEditViewModelParams) => new WidgetImageEditViewModel(params),
    template: <string>require('./widget-image-edit.html')
});
