import * as ko from 'knockout'
import {components} from 'knockout'
import {DepartmentDto, InstitutionDto} from "../../../api/generated";
import {autobind, observable, unwrap} from "knockout-decorators";
import {postbox} from "../../../components/util/postbox";
import {dataApi} from "../../../api/api-wrapper";
import {createDepartment} from '../../../components/util/common';
import Config = components.Config;
import globalState from "../../../global-state";
import {createConfirmModal} from "../../../components/elements/modal/modal";
import i18nextko from "../../../bindings/i18nko";
import {Router} from "@profiscience/knockout-contrib-router";

interface ViewModelParams {
    institution: InstitutionDto;
    department: DepartmentDto;
    successCallback: (vm: DepartmentEditViewModel) => void;
    cancelCallback: (vm: DepartmentEditViewModel) => void;
}

export class DepartmentEditViewModel {

    @observable
    institution: InstitutionDto;

    @observable({deep: true, expose: true})
    department: DepartmentDto;

    public successCallback: (vm: DepartmentEditViewModel) => void;
    public cancelCallback: (vm: DepartmentEditViewModel) => void;

    constructor(params: ViewModelParams) {
        this.institution = params.institution;
        this.department = params.department ? Object.assign({}, this.createDepartment(), params.department) : this.createDepartment();

        this.successCallback = params.successCallback || function() {};
        this.cancelCallback = params.cancelCallback || function() {};

        unwrap(this.department, "name").extend({required: true, maxlength: 255});
        unwrap(this.department, "phone").extend({required: false, maxlength: 255 });
        unwrap(this.department, "fax").extend({required: false, maxlength: 255 });
        unwrap(this.department, "email").extend({required: false, email: true, maxlength: 255 });
        unwrap(this.department, "website").extend({required: false, maxlength: 255 });
    }

    @autobind
    public save() {

        const errors = ko.validation.group(this.department)
        if (errors().length > 0) {
            errors.showAllMessages();
            postbox.addError('validation.failed');
            return;
        }

        globalState.loading(true);

        if (this.department.id && this.department.id.trim().length > 0) {
            dataApi.putDepartment(this.department.id, this.department)
                .then(response => {
                    this.department = response.data;
                    postbox.addSuccess('department.success.save');
                    this.successCallback(this);
                    return response.data;
                })
                .catch(error => {
                    postbox.addError('department.error.save');
                    return Promise.reject();
                })
                .finally(globalState.loading(false));
        } else {
            this.department.institution = this.institution;
            dataApi.postDepartment(this.department)
                .then(response => {
                    this.department = response.data;
                    postbox.addSuccess('department.success.save');
                    this.successCallback(this);
                    return response.data;
                })
                .catch(error => {
                    postbox.addError('department.error.save');
                    return Promise.reject();
                })
                .finally(globalState.loading(false));
        }
    }

    public cancel() {
        this.cancelCallback(this);
    }

    /**
     * Delete the institution with a confirm dialog.
     */
    @autobind
    public deleteDepartment() {
        return createConfirmModal(
            i18nextko.t("department.delete.confirmText"), i18nextko.t("department.delete.confirmTitle"),
            i18nextko.t("global.delete"),
            i18nextko.t("global.cancel")
        )
            .then(() => {
                globalState.loading(true);
                return dataApi.deleteDepartment(this.department.id).then(() => {
                    postbox.addSuccess('department.delete.success');
                    Router.update("/admin/institutionen/" + this.institution.id)
                })
                    .catch(err => {
                        postbox.addError('department.delete.error');
                        return Promise.reject(err);
                    })
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => globalState.loading(false));
    }

    createDepartment() {
        return createDepartment();
    }

    public info() {
        console.debug("Department", this.department);
    }
}

const component: Config = {
    viewModel: (params: ViewModelParams) => new DepartmentEditViewModel(params),
    template: <string>require('./department-edit.html')
};

export default component;

if (!ko.components.isRegistered('department-edit')) {
    ko.components.register('department-edit', component)
}
