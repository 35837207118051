// noinspection JSUnusedGlobalSymbols

import * as ko from "knockout";
import {Computed, Observable, ObservableArray, Subscription} from "knockout";
import {Context} from "@profiscience/knockout-contrib-router";
import {InstitutionDtoTypeEnum, PersonDtoTypeEnum, SearchResultItemDto, WidgetDto} from "../../api/generated";
import {contentApi, dataApi} from "../../api/api-wrapper";
import {autobind, computed, observable, observableArray} from "knockout-decorators";
import {saveWidget} from "../editor/widget-utils";
import * as L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/images/marker-icon-2x.png";
import "leaflet/dist/images/marker-shadow.png";
import "../../../images/marker-icon-doctor.png";
import "../../../images/marker-icon-therapist.png";
import "../../../images/marker-icon-home.png";
import "../../components/elements/search-result/search-result-item"
import "../../components/elements/search-result/search-filter-group"
import i18nextko from "../../bindings/i18nko";
import {SearchResultItem} from "../../components/elements/search-result/search-result-item";
import "./home-public";
import "./home-docview"
import {markerIcon} from "../../components/util/common";

class ViewModelContext extends Context {
    mainWidget: WidgetDto;
    boxWidget: WidgetDto;
    searchResultItemDtos: SearchResultItemDto[];
}


export class SearchFilterItem {

    name: string;
    term: string;
    hits: number;

    constructor(name: string, term: string) {
        this.name = name;
        this.term = term;
        this.hits = 1;
    }

    public increaseHits(hits: number) {
        this.hits = this.hits + hits;
    }
}

enum SortBy {
    Relevance,
    Distance,
    AZ,
    ZA
}

class ViewModel {
    mainWidget: WidgetDto;
    mainEditing: Observable<boolean>;
    boxWidget: WidgetDto;
    boxEditing: Observable<boolean>;

    /**
     * The search term from the form.
     */
    @observable
    searchTerm: string;

    /**
     * The location search term.
     */
    @observable
    searchTermLocation: string;

    /**
     * The current location resolved from the location search term.
     */
    @observable
    currentSearchLocation: any;

    /**
     * The flag if a search result should be rendered.
     */
    @observable
    showSearchResult: boolean;

    /**
     * All available result items.
     */
    searchResultItems: SearchResultItem[];

    /**
     * Result items which match the search term and the location.
     */
    @observableArray
    searchResult: SearchResultItem[];

    /**
     * Result items filtered by search filter terms.
     */
    public searchResultFiltered: Computed<SearchResultItem[]>;

    /**
     * Result items, sorted and limited to a page size.
     */
    public searchResultPaged: Computed<SearchResultItem[]>;

    @observable
    sortBy: SortBy;

    /**
     * Search result subscription.
     * Resets the current pagination page whenever the length of the search result changes.
     */
    public searchResultSubscription: Subscription;

    /**
     * The current page number for the pagination.
     */
    public currentPage: Observable<number>;

    /**
     * The number of items per page.
     */
    public pageSize: Observable<number>

    /**
     * The selected institution type filter.
     */
    public selectedInstitutionTypes: ObservableArray<string>;

    /**
     * The selected areasOfSubject filter.
     */
    public selectedAreasOfSubject: ObservableArray<string>;

    /**
     * The selected keyAspects filter.
     */
    public selectedKeyAspects: ObservableArray<string>;

    /**
     * The map.
     */
    map: L.Map;

    /**
     * The map layer to add / remove the markers for the current result.
     */
    mapFeatureGroup: L.FeatureGroup;

    /**
     * The search radius in Kilometers.
     */
    searchRadius: Observable<number>;

    /**
     * Search again if the radius changes.
     */
    searchRadiusSubscription: Subscription;

    /**
     * Search result shows doctors
     */
    showDoctors: Observable<boolean>;

    /**
     * Search again if the showDoctors changes.
     */
    showDoctorsSubscription: Subscription;

    /**
     * Search result shows therapists.
     */
    showTherapists: Observable<boolean>;

    /**
     * Search again if the showTherapists changes.
     */
    showTherapistsSubscription: Subscription;


    constructor(ctx: ViewModelContext) {
        this.mainWidget = ctx.mainWidget;
        this.mainEditing = ko.observable(false);
        this.boxWidget = ctx.boxWidget;
        this.boxEditing = ko.observable(false);
        this.searchResultItems = ctx.searchResultItemDtos.map(dto => new SearchResultItem(dto));
        this.searchTerm = "";
        this.searchTermLocation = "";
        this.showSearchResult = false;
        this.searchResult = [];
        this.searchResultFiltered = this.initSearchResultFiltered();
        this.searchResultPaged = this.initSearchResultPaged();
        this.pageSize = ko.observable(50);
        this.currentPage = ko.observable(1);
        this.selectedInstitutionTypes = ko.observableArray([]);
        this.selectedAreasOfSubject = ko.observableArray([]);
        this.selectedKeyAspects = ko.observableArray([]);
        this.sortBy = SortBy.Relevance;
        this.map = this.initMap();
        this.mapFeatureGroup = new L.FeatureGroup([]).addTo(this.map);
        this.currentSearchLocation = null;
        this.searchRadius = ko.observable(100);
        this.searchRadiusSubscription = this.searchRadius.subscribe(() => {
            this.performSearch();
        });
        this.showDoctors = ko.observable(true);
        this.showDoctorsSubscription = this.showDoctors.subscribe(() => {
            this.performSearch();
        });
        this.showTherapists = ko.observable(true);
        this.showTherapistsSubscription = this.showTherapists.subscribe(() => {
            this.performSearch();
        });
    }

    @computed
    get searchTerms(): string[] {
        return this.searchTerm.trim().split(" ").filter(term => term != "").map(term => term.toLowerCase());
    }

    @computed
    get searchRadiusInMeters(): number {
        return this.searchRadius() * 1000;
    }

    @computed
    get filterOptionsInstitutionType(): SearchFilterItem[] {
        const counterMap: Map<string, SearchFilterItem> = this.searchResultFiltered()
            .reduce((map: any, searchResultItem: SearchResultItem) => {
                const filterTerm: InstitutionDtoTypeEnum = searchResultItem.institution.type;
                if (!map.has(filterTerm)) {
                    const name = i18nextko.t("institution.type." + filterTerm.toLowerCase())();
                    map.set(filterTerm, new SearchFilterItem(name, filterTerm.toLowerCase()));
                } else {
                    map.get(filterTerm).increaseHits(1);
                }
                return map;
            }, new Map<string, SearchFilterItem>());
        return Array.from(counterMap.values())
            .sort((rc1, rc2) => rc2.hits - rc1.hits);
    }

    @computed
    get filterOptionsAreasOfSubject(): SearchFilterItem[] {
        const counterMap: Map<string, SearchFilterItem> = this.searchResultFiltered()
            .reduce((map: any, searchResultItem: SearchResultItem) => {
                searchResultItem.dto.areasOfSubject.forEach(filterTerm => {
                    if (!map.has(filterTerm)) {
                        map.set(filterTerm, new SearchFilterItem(filterTerm, filterTerm.toLowerCase()));
                    } else {
                        map.get(filterTerm).increaseHits(1);
                    }
                })
                return map;
            }, new Map<string, SearchFilterItem>());
        return Array.from(counterMap.values())
            .sort((rc1, rc2) => rc2.hits - rc1.hits);
    }

    @computed
    get filterOptionsKeyAspects(): SearchFilterItem[] {
        const counterMap: Map<string, SearchFilterItem> = this.searchResultFiltered()
            .reduce((map: any, searchResultItem: SearchResultItem) => {
                searchResultItem.dto.keyAspects.forEach(filterTerm => {
                    if (!map.has(filterTerm)) {
                        map.set(filterTerm, new SearchFilterItem(filterTerm, filterTerm.toLowerCase()));
                    } else {
                        map.get(filterTerm).increaseHits(1);
                    }
                })
                return map;
            }, new Map<string, SearchFilterItem>());
        return Array.from(counterMap.values())
            .sort((rc1, rc2) => rc2.hits - rc1.hits);
    }

    @computed
    get isSearchActive(): boolean {
        return this.searchTerms.length > 0 || this.isLocationSearch;
    }

    @computed
    get isLocationSearch(): boolean {
        return this.searchTermLocation.trim() != "";
    }

    @autobind
    public search() {
        this.resetFilters();
        this.performSearch();
    }

    @autobind
    public performSearch() {
        console.debug("Searching for", this.searchTerm, this.searchTermLocation);

        if(!this.isSearchActive) {
            this.searchResult = [];
            this.showSearchResult = false;
            return;
        }

        this.sortBy = SortBy.Relevance;
        let searchResult = this.searchResultItems;

        // Filter result by search terms
        this.searchTerms.forEach(term => {
            searchResult = searchResult.filter(resultItem =>
                this.matchInstitution(resultItem, term)
                || this.matchDepartment(resultItem, term)
                || this.matchPerson(resultItem, term)
            )
        });

        // Filter result by person type
        if (this.showDoctors() === false && this.showTherapists() === false) {
            // ignore to avoid empty search result

        } else if (this.showDoctors() === false) {
            searchResult = searchResult.filter((resultItem =>
                resultItem.person.type !== PersonDtoTypeEnum.Doctor))
        } else if (this.showTherapists() === false) {
            searchResult = searchResult.filter((resultItem =>
                resultItem.person.type !== PersonDtoTypeEnum.Therapist))
        }

        // Filter result by location
        this.currentSearchLocation = null;
        if(this.searchTermLocation.trim() != "") {
            this.getCurrentSearchLocation().then(location => {
                console.debug("perform location search for", location);
                if(location !== null) {
                    this.sortBy = SortBy.Distance;
                    this.currentSearchLocation = location;
                    searchResult = this.filterByLocation(searchResult, location).sort((res1, res2) =>
                        res1.distance - res2.distance
                    );
                }
                console.debug("searchResult", searchResult);
                this.searchResult = searchResult;
                if(searchResult.length > 0) {
                    this.addMarkersToMap(location);
                }
                this.showSearchResult = true;
            });
        } else {
            console.debug("searchResult", searchResult);
            searchResult.forEach(item => item.distance = 0);
            this.searchResult = searchResult;
            if(searchResult.length > 0) {
                this.addMarkersToMap(null);
            }
            this.showSearchResult = true;
        }
    }

    private resetFilters() {
        this.selectedInstitutionTypes([]);
        this.selectedAreasOfSubject([]);
        this.selectedKeyAspects([]);
    }

    @autobind
    public saveMainWidget() {
        return saveWidget(this.mainWidget).then(() => this.mainEditing(false));
    }

   @autobind
    public saveBoxWidget() {
        return saveWidget(this.boxWidget).then(() => this.boxEditing(false));
    }

    private matchInstitution(item: SearchResultItem, term: string): boolean {
        if(item.institution.name.toLowerCase().indexOf(term.toLowerCase()) > -1) {
           item.weight++;
           return true;
        }
        return false;
    }

    private matchDepartment(item: SearchResultItem, term: string): boolean {
        if(item.department.name.toLowerCase().indexOf(term.toLowerCase()) > -1) {
            item.weight++;
            return true;
        }
        return false;
    }

    private matchPerson(item: SearchResultItem, term: string): boolean {
        let weight = 0;

        if((item.person.firstName + " " + item.person.lastName).toLowerCase().indexOf(term.toLowerCase()) > -1) {
            weight++;
        }
        if(this.matchAreasOfSubject(item, term)) {
            weight++;
        }
        if(this.matchKeyAspects(item, term)) {
            weight++;
        }

        item.weight += weight;

        return weight == 0 ? false : true;
    }

    private matchAreasOfSubject(item: SearchResultItem, term: string): boolean {
        return item.dto.areasOfSubject.join(" ").toLowerCase().indexOf(term) > -1 ? true : false;
    }

    private matchKeyAspects(item: SearchResultItem, term: string): boolean {
        return item.dto.keyAspects.join(" ").toLowerCase().indexOf(term) > -1 ? true : false;
    }

    private filterByLocation(searchResult: SearchResultItem[], location): SearchResultItem[] {
        const currenLatLng  = new L.LatLng(location.lat, location.lon);
        const locationSearchResult: SearchResultItem[] = [];
        searchResult.forEach(result => {
            result.distance = currenLatLng.distanceTo(result.marker.getLatLng());
            if (result.distance < this.searchRadiusInMeters) {
                locationSearchResult.push(result);
            }
        });
        return locationSearchResult;
    }

    async getCurrentSearchLocation() {
        const nominatimUrl = new URL("https://nominatim.openstreetmap.org/search");
        nominatimUrl.searchParams.append("format", "json");
        nominatimUrl.searchParams.append("q", "Österreich, " + this.searchTermLocation.trim());
        nominatimUrl.searchParams.append("limit", "1");

        console.debug("Starting fetch");
        const response = await fetch(nominatimUrl.href);
        if (!response.ok) {
            const message = `An error has occured: ${response.status}`;
            throw new Error(message);
        }
        const locations = await response.json();
        console.debug("Ending fetch");
        return locations.length > 0 ? locations[0] : null;
    }

    @autobind
    private initMap(): L.Map {
        // Init with zoom to Austria
        const map = L.map('map').setView({ "lat": 47.67528751902042, "lng": 13.19405034184456 }, 7);

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);

        return map
    }

    @autobind
    public setSortRelevance() {
        console.debug("sort rel");
        this.sortBy = SortBy.Relevance;
    }

    @autobind
    public setSortDistance() {
        console.debug("sort dist");
        this.sortBy = SortBy.Distance;
    }

    @autobind
    public setSortNameAsc() {
        console.debug("sort asc");
        this.sortBy = SortBy.AZ;
    }

    @autobind
    public setSortNameDesc() {
        console.debug("sort desc");
        this.sortBy = SortBy.ZA;
    }

    @autobind
    private sortByRelevance(searchResult: SearchResultItem[]) {
        return searchResult.sort((res1, res2) =>
            res2.weight - res1.weight
        );
    }

    @autobind
    private sortByDistance(searchResult: SearchResultItem[]) {
        return searchResult.sort((res1, res2) =>
            res1.distance - res2.distance
        );
    }

    @autobind
    private sortByNameAsc(searchResult: SearchResultItem[]) {
        return searchResult.sort((res1, res2) => {
            const name1 = res1.person.lastName.toLowerCase();
            const name2 = res2.person.lastName.toLowerCase();
            return (name1 < name2) ? -1 : (name1 > name2) ? 1 : 0;
        });
    }

    @autobind
    private sortByNameDesc(searchResult: SearchResultItem[]) {
        return searchResult.sort((res1, res2) => {
            const name1 = res1.person.lastName.toLowerCase();
            const name2 = res2.person.lastName.toLowerCase();
            return (name2 < name1) ? -1 : (name2 > name1) ? 1 : 0;
        });
    }

    @autobind
    private addMarkersToMap(location) {

        // clear existing markers
        this.mapFeatureGroup.clearLayers();

        // add markers for result items
        this.searchResult.forEach(resultItem => {
            this.mapFeatureGroup.addLayer(resultItem.marker);
        });

        // add marker for current location
        if(location) {
            const currentLocation = L.marker(new L.LatLng(location.lat, location.lon), {
                    icon: markerIcon('home')
                })
                .bindPopup("Ihr Standort");
            this.mapFeatureGroup.addLayer(currentLocation);
            //currentLocation.openPopup();
        }

        // zoom to fit all markers
        this.map.fitBounds(this.mapFeatureGroup.getBounds(),{padding: L.point(100, 100)});
    }


    /**
     *  Result sorted and limited to a page size.
     */
    @autobind
    private initSearchResultFiltered(): Computed<SearchResultItem[]> {
        return ko.pureComputed(() => {

            let searchResultFiltered = this.searchResult;

            console.debug(this.selectedInstitutionTypes(), this.selectedAreasOfSubject(), this.selectedKeyAspects());

            this.selectedInstitutionTypes().forEach(filterTerm =>
                searchResultFiltered = searchResultFiltered.filter(item =>
                    item.institution.type.toString().toLowerCase() == filterTerm));

            this.selectedAreasOfSubject().forEach(filterTerm =>
                searchResultFiltered = searchResultFiltered.filter(item =>
                    this.matchAreasOfSubject(item, filterTerm)));

            this.selectedKeyAspects().forEach(filterTerm =>
                searchResultFiltered = searchResultFiltered.filter(item =>
                    this.matchKeyAspects(item, filterTerm)));

            return searchResultFiltered;
        });
    }


    /**
     *  Result sorted and limited to a page size.
     */
    @autobind
    private initSearchResultPaged(): Computed<SearchResultItem[]> {
        return ko.pureComputed(() => {

            let searchResult = this.searchResultFiltered();

            console.debug("searchResultPaged", searchResult);
            const pageStart = (this.currentPage() - 1) * this.pageSize();
            const pageEnd = pageStart + this.pageSize();
            console.debug("total items: ", searchResult.length, " pagination start: ", pageStart, " pagination end: ", pageEnd);

            if(this.sortBy == SortBy.Distance) {
                searchResult = this.sortByDistance(searchResult);
            } else if(this.sortBy == SortBy.AZ) {
                searchResult = this.sortByNameAsc(searchResult)
            } else if(this.sortBy == SortBy.ZA) {
                searchResult = this.sortByNameDesc(searchResult)
            } else {
                searchResult = this.sortByRelevance(searchResult);
            }

            return searchResult.slice(pageStart, pageEnd);
        });
    }

    /**
     * Dispose subscriptions.
     */
    public dispose() {
        this.searchRadiusSubscription.dispose();
    }

}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./home.html'),
    componentName: "home",
    loader: (ctx: ViewModelContext) => {
        return Promise.all([
            contentApi.getWidget(config.widgetIds.home)
                .then(response => ctx.mainWidget = response.data)
                .catch(err => {
                    console.error(err.message);
                    return Promise.reject(err);
                }),
            contentApi.getWidget(config.widgetIds.demoBox)
                .then(response => ctx.boxWidget = response.data)
                .catch(err => {
                    console.error(err.message);
                    return Promise.reject(err);
                }),
            dataApi.getPersonDepartments().then(response => ctx.searchResultItemDtos = response.data),
        ]);
    }
};
