// noinspection JSUnusedGlobalSymbols

import * as ko from "knockout";
import {Observable} from "knockout";
import {Context} from "@profiscience/knockout-contrib-router";
import {WidgetDto} from "../../api/generated";
import {contentApi} from "../../api/api-wrapper";
import {autobind} from "knockout-decorators";
import {saveWidget} from "../editor/widget-utils";
import "./service-docview";
import "./service-public";

class ViewModelContext extends Context {
    mainWidget: WidgetDto;
}

class ViewModel {
    mainWidget: WidgetDto;
    mainEditing: Observable<boolean>;

    constructor(ctx: ViewModelContext) {
        this.mainWidget = ctx.mainWidget;
        this.mainEditing = ko.observable(false);
    }

    @autobind
    public saveMainWidget() {
        return saveWidget(this.mainWidget).then(() => this.mainEditing(false));
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./service.html'),
    componentName: "downloads",
    loader: (ctx: ViewModelContext) => {
        return contentApi.getWidget(config.widgetIds.downloads)
            .then(response => ctx.mainWidget = response.data)
            .catch(err => {
                console.error(err.message);
                return Promise.reject(err);
            });
    }
};
