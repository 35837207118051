import {
    AddressDto,
    DepartmentDto,
    InstitutionDto,
    InstitutionDtoTypeEnum,
    PersonDto,
    PersonDtoGenderEnum,
    PersonDtoTypeEnum,
    SearchResultItemDto,
    SearchResultItemDtoInsurancesEnum
} from "../../api/generated";


export function createDemoData(): SearchResultItemDto[] {

    const data: SearchResultItemDto[] = [];

    // Dr. Thomas Sycha - Ordination Hietzing
    let areaOfSubjects = ["Neurologie"];
    let keyAspects = ["Schmerz", "Schlaganfall (Vor-/Nachsorge)", "Multiple Sklerose", "Bewegungstörungen"]
    let person = createPerson(PersonDtoTypeEnum.Doctor, PersonDtoGenderEnum.Male,
        "Univ.-Prof. Dr.", "Thomas", "Sycha");
    let department = createDepartment("Neurologie");
    let address = createAddress("Wien", "1130", "Trauttmansdorffgasse", " 34-36", "4", 48.184192100000004,16.29573667404413)
    let institution = createInstitution(InstitutionDtoTypeEnum.Privat, "Ordination 1130 Wien", address, [])
    data.push(createSearchResultItemDto(institution, department, person, areaOfSubjects, keyAspects));

    // Dr. Thomas Sycha - Ordination Feistritz am Wechsel
    address = createAddress("Feistritz am Wechsel", "2873", "Feistritz am Wechsel", "27", null, 47.6153656, 16.0706751);
    institution = createInstitution(InstitutionDtoTypeEnum.Privat, "Ordination 2873 Feistritz am Wechsel", address, [])
    data.push(createSearchResultItemDto(institution, department, person, areaOfSubjects, keyAspects));

    // Dr. Thomas Sycha - AKH
    department = createDepartment("Univ. Klinik für Neurologie");
    address = createAddress("Wien", "1090", "Währinger Gürtel", "18-20", null, 48.2196227, 16.347722925741976);
    institution = createInstitution(InstitutionDtoTypeEnum.Hospital, "Universitätsklinikum AKH Wien", address, [])
    data.push(createSearchResultItemDto(institution, department, person, areaOfSubjects, keyAspects));



    // Dr.med.univ. Sabine Groicher Fachärztin für Neurologie
    // Trattnach Arkade 1/2
    // 4710 Grieskirchen
    areaOfSubjects = ["Neurologie", "Kosmetische Behandlung"];
    keyAspects = ["Kopf und Gesichtsschmerzen", "Bandscheibenerkrankungen und andere Schmerzerkrankungen", "Multiple Sklerose", "Bewegungstörungen", "Epilepsien"]
    person = createPerson(PersonDtoTypeEnum.Doctor, PersonDtoGenderEnum.Female,
        "Dr.med.univ.", "Sabine", "Groicher");
    department = createDepartment("Neurologie");
    address = createAddress("Grieskirchen", "4710", "Trattnach Arkade", "1/2", null, 48.2313314,13.8321332)
    institution = createInstitution(InstitutionDtoTypeEnum.Privat, "Neurologie & MS-Zentrum Dr. Groicher", address, [])
    data.push(createSearchResultItemDto(institution, department, person, areaOfSubjects, keyAspects));

    // Dr. Barbara Pischinger Ordination Leonding
    // Rathausgasse 4
    areaOfSubjects = ["Neurologie", "Allgemeinmedizin", "Geriatrie"];
    keyAspects = ["Schmerz", "Schlaganfall (Vor-/Nachsorge)", "Multiple Sklerose", "Bewegungstörungen"]
    person = createPerson(PersonDtoTypeEnum.Doctor, PersonDtoGenderEnum.Female,
        "Dr.med.univ.", "Barbara", "Pischinger");
    department = createDepartment("Neurologie");
    address = createAddress("Leonding", "4060", "Rathausgasse", "4", null, 48.2791652,14.2474765)
    institution = createInstitution(InstitutionDtoTypeEnum.Privat, "Ordination Dr. Barbara Pischinger", address, [])
    data.push(createSearchResultItemDto(institution, department, person, areaOfSubjects, keyAspects));

    return data;
}

function createSearchResultItemDto(institution: InstitutionDto, department: DepartmentDto, person: PersonDto,areasOfSubject: string[], keyAspects: string[]): SearchResultItemDto {
    return {
        institution: institution,
        department: department,
        person: person,
        areasOfSubject: areasOfSubject,
        keyAspects: keyAspects,
        insurances: [SearchResultItemDtoInsurancesEnum.Svs],
        openingHours: [],
        phone: "+43 0 0000000",
        fax: "+43 0 0000000-0",
        email: "example@mail.com",
        website: "www.example.com"
    }
}


function createInstitution(type:InstitutionDtoTypeEnum, name: string, address: AddressDto, departments: DepartmentDto[]): InstitutionDto {
    return {
        id: generateUUID(),
        type: type,
        name: name,
        barrierFree: true,
        address: address,
        departments: departments
    }
}

function createDepartment(name: string): DepartmentDto {
    return {
        name: name,
        phone: "+43 0 0000000",
        fax: "+43 0 0000000-0",
        email: "example@mail.com",
        website: "www.example.com"
    }
}

function createAddress(city: string, zip: string, street: string, housenumber: string, staircase: string, latitude: number, longitude: number ): AddressDto {
    return {
        country: "Österreich",
        city: city,
        zip: zip,
        street: street,
        housenumber: housenumber,
        staircase: staircase,
        doornumber: '7',
        latitude: latitude,
        longitude: longitude
    }
}

function createPerson(type: PersonDtoTypeEnum, gender: PersonDtoGenderEnum, title: string, firstName: string, lastName: string): PersonDto {
    return {
        id: generateUUID(),
        type: type,
        gender: gender,
        title: title,
        titlePostgrade: "",
        titleConferred: "",
        firstName: firstName,
        lastName: lastName,
        certified: true,
        languages: [],
    }
}

function generateUUID() { // Public Domain/MIT
    let d = new Date().getTime();//Timestamp
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

