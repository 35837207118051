import * as ko from 'knockout'
import {components, Observable, ObservableArray} from 'knockout'
import {PersonDto} from "../../../api/generated";
import {autobind, computed, observable, unwrap} from "knockout-decorators";
import {postbox} from "../../../components/util/postbox";
import {dataApi} from "../../../api/api-wrapper";
import {
    createPerson,
    createProfileImage, isAdminView,
    personGenderOptions,
    personTypeOptions
} from '../../../components/util/common';
import {FileData} from "../../editor/_common";
import i18nextko from "../../../bindings/i18nko";
import globalState from "../../../global-state";
import Config = components.Config;
import {createConfirmModal} from "../../../components/elements/modal/modal";
import {Router} from "@profiscience/knockout-contrib-router";
import * as moment from "moment";

interface ViewModelParams {
    person: PersonDto;
    successCallback: (vm: PersonEditViewModel) => void;
    cancelCallback: (vm: PersonEditViewModel) => void;
}

export class PersonEditViewModel {

    public imageBasePath: string = config.profileImageEndpoint;

    @observable({deep: true, expose: true})
    person: PersonDto;

    public imageData: Observable<FileData>;

    public successCallback: (vm: PersonEditViewModel) => void;
    public cancelCallback: (vm: PersonEditViewModel) => void;

    languageOptions: ObservableArray<string>;

    constructor(params: ViewModelParams) {
        this.person = params.person || this.createPerson();
        this.imageData = ko.observable({
            dataURL: ko.observable(),
            base64String: ko.observable(),
            file: ko.observable()
        });

        this.successCallback = params.successCallback || function() {};
        this.cancelCallback = params.cancelCallback || function() {};

        this.languageOptions = ko.observableArray([
            "Albanisch", "Bosnisch", "Burgenlandkroatisch", "Deutsch", "Englisch", "Französisch", "Griechisch",
            "Griechisch (Neu)", "Italienisch", "Kroatisch", "Kurdisch", "Mazedonisch",
            "Österreichische Gebärdensprache", "Polnisch", "Portugiesisch", "Romani", "Russisch", "Serbisch",
            "Slowakisch", "Slowenisch", "Spanisch", "Tschechisch", "Türkisch", "Ukrainisch", "Ungarisch",
            "Windisch"
        ]);

        unwrap(this.person, "type").extend({required: true, maxlength: 255});
        unwrap(this.person, "gender").extend({required: true, maxlength: 255});
        unwrap(this.person, "firstName").extend({required: true, maxlength: 255});
        unwrap(this.person, "lastName").extend({required: true, maxlength: 255});
        unwrap(this.person, "email").extend({required: true, email: true, maxlength: 255 });
        unwrap(this.person, "phone").extend({required: false, maxlength: 255 });
    }

    @autobind
    public save() {

        const errors = ko.validation.group(this.person)
        if (errors().length > 0) {
            errors.showAllMessages();
            postbox.addError('validation.failed');
            return;
        }

        let profileImage = null;
        if (this.imageData() && this.imageData().base64String()) {
            if (this.imageData().file().type.search(/^image\/.*$/) == -1) {
                postbox.addError('widget.image.error.invalidType');

                return Promise.reject();
            } else {
                profileImage = Object.assign({},
                    this.person.profileImage || createProfileImage(),
                    {
                        filename: this.imageData().file().name,
                        mimeType: this.imageData().file().type,
                        data: this.imageData().base64String()
                    });
            }
        }

        globalState.loading(true);

        if (this.person.id && this.person.id.trim().length > 0) {
            dataApi.putPerson(this.person.id, this.person)
                .then(response => {
                    this.person = response.data
                    return response.data;
                }).then(person => {
                    if(profileImage) {
                        return dataApi.postProfileImage(person.id, profileImage)
                            .then(response => this.person = response.data);
                    }
                    return person;
                }).then(() => {
                    postbox.addSuccess('person.success.save');
                    this.successCallback(this);
                })
                .catch(error => {
                    postbox.addError('person.error.save');
                    return Promise.reject();
                })
                .finally(globalState.loading(false));
        } else {
            dataApi.postPerson(this.person)
                .then(response => {
                    this.person = response.data
                    return response.data;
                }).then(person => {
                    if(profileImage) {
                        return dataApi.postProfileImage(person.id, profileImage)
                            .then(response => this.person = response.data);
                    }
                    return person;
                }).then(() => {
                    postbox.addSuccess('person.success.save');
                    this.successCallback(this);
                })
                .catch(error => {
                    postbox.addError('person.error.save');
                    return Promise.reject();
                })
                .finally(globalState.loading(false));
        }
    }

    @autobind
    public cancel() {
        this.cancelCallback(this);
    }

    /**
     * Delete the person with a confirm dialog.
     */
    @autobind
    public deletePerson() {
        return createConfirmModal(
            i18nextko.t("person.delete.confirmText"), i18nextko.t("person.delete.confirmTitle"),
            i18nextko.t("global.delete"),
            i18nextko.t("global.cancel")
        )
            .then(() => {
                globalState.loading(true);
                return dataApi.deletePerson(this.person.id).then(() => {
                    postbox.addSuccess('person.delete.success');
                    Router.update("/admin/personen")
                })
                    .catch(err => {
                        postbox.addError('person.delete.error');
                        return Promise.reject(err);
                    })
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => globalState.loading(false));
    }

    @computed
    get profileImagePath() {
        return `${config.profileImageEndpoint}${this.person.profileImage.id}/${moment(this.person.profileImage.modified).valueOf()}`;
    }

    /**
     * Get the options for the person type.
     */
    public personTypeOptions() {
        return personTypeOptions();
    }

    /**
     * Get the options for the person gender.
     */
    public personGenderOptions() {
        return personGenderOptions();
    }

    createPerson(): PersonDto {
        return createPerson();
    }

    public imageFileOptions() {
        return {
            noFileText: i18nextko.t("widget.modal.file.noFileText"),
            buttonText: i18nextko.t("widget.modal.file.button.choose"),
            changeButtonText: i18nextko.t("widget.modal.file.button.change"),
            clearButtonText: i18nextko.t("widget.modal.file.button.clear")
        };
    }

    public get isAdminView() {
        return isAdminView();
    }
}

const component: Config = {
    viewModel: (params: ViewModelParams) => new PersonEditViewModel(params),
    template: <string>require('./person-edit.html')
};

export default component;

if (!ko.components.isRegistered('person-edit')) {
    ko.components.register('person-edit', component)
}
