// noinspection JSUnusedGlobalSymbols
import * as ko from "knockout";
import {Context, Router} from "@profiscience/knockout-contrib-router";
import {autobind, observable} from "knockout-decorators";
import {AddressDto, DepartmentDto, InstitutionDto} from "../../../api/generated";
import {dataApi} from "../../../api/api-wrapper";
import institution from "./institution";
import {createAddress, createDepartment, createInstitution, dataTableOptions} from "../../../components/util/common";
import "./institution-edit"
import "../department/department-edit"
import "../../../bindings/knockout-datatables-foreach"
import {DepartmentEditViewModel} from "../department/department-edit";

class ViewModelContext extends Context {
    institution: InstitutionDto;
    departments: DepartmentDto[]
}

class ViewModel {

    @observable({deep: true, expose: true})
    institution: InstitutionDto;

    @observable({deep: true, expose: true})
    departments: DepartmentDto[];

    @observable({deep: true, expose: true})
    department: DepartmentDto;

    @observable
    departmentEditing: boolean;

    constructor(ctx: ViewModelContext) {
        this.institution = this.ensureObservableProperties(ctx.institution);
        this.departments = ctx.departments || [];
        this.department = this.createDepartment();
        this.departmentEditing = false;
    }

    createInstitution(): InstitutionDto {
        return createInstitution();
    }

    createAddress(): AddressDto {
        return createAddress()
    }

    createDepartment() {
        return createDepartment();
    }

    @autobind
    addDepartment() {
        this.departmentEditing = true;
        this.department = createDepartment();
    }

    @autobind
    editDepartment(department: DepartmentDto) {
        console.log("edit department", department);
        this.departmentEditing = true;
        this.department = this.departments.find(d => d.id == department.id);
    }

    /**
     * Ensure all address properties exist on initialization to make them editable.
     * @param institution
     * @private
     */
    private ensureObservableProperties(institution: InstitutionDto) {
        institution = institution ? Object.assign({}, this.createInstitution(), institution) : this.createInstitution();
        institution.address = institution.address ? Object.assign({}, this.createAddress(), institution.address) : this.createAddress();
        return institution;
    }

    @autobind
    public institutionSuccessCallback(vm: DepartmentEditViewModel) {
        Router.update(`/admin/institutionen/${vm.institution.id}` );
    }

    @autobind
    public institutionCancelCallback(vm: DepartmentEditViewModel) {
        Router.update("/admin/institutionen")
    }

    @autobind
    public departmentSuccessCallback(vm: DepartmentEditViewModel) {
        const department = vm.department;
        console.log("callback", department);
        const index = this.departments.findIndex(d => d.id == department.id );
        if(index === -1) {
            this.departments.push(department);
            console.log("push", this.departments);
        } else {
            this.departments.splice(index, 1, department);
            console.log("splice", this.departments);
        }
        this.departmentEditing = false;
    }

    @autobind
    public departmentCancelCallback(vm: DepartmentEditViewModel) {
        this.departmentEditing = false;
    }

    public dataTableOptions() {
        return dataTableOptions();
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./institution.html'),
    componentName: "institution",
    loader: (ctx: ViewModelContext) => {
        const id = ctx.params.id;
        if (id == null) {
            ctx.institution = null;
            ctx.departments = [];
            return Promise.resolve();
        }
        return Promise.all([
            dataApi.getInstitution(id).then(response => ctx.institution = response.data),
            dataApi.getInstitutionDepartments(id).then(response => ctx.departments = response.data)
        ]);
    }
};
