// noinspection JSUnusedGlobalSymbols

import {Context, Router} from "@profiscience/knockout-contrib-router";
import {App} from "../../app";
import globalState from "../../global-state";

class ViewModelContext extends Context {
}

class ViewModel {

    constructor(ctx: ViewModelContext) {
        globalState.docChecked(App.setDocChecked(true));
        globalState.docCheckedView(App.setDocCheckedView(true));
        return Router.update("/", { force: true});
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./success.html'),
    componentName: "success",
    loader: (ctx: ViewModelContext) => {
        return Promise.resolve();
    }
};
